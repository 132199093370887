.about-container{
  width: 95%;
  margin: 0 auto;
  background-color: #EFEDEF;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
}
.about-title{
  color: #E9A45D;
  font-size: 46px;
  margin: 25px 0;
}

.mision-container{
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.mision-title{
  font-size: 46px;
  color: #E9A45D;
  margin-bottom: 20px;
}

.mision-text{
  color: #606060;
  font-size: 26px;
  margin: 20px auto;
  font-weight: 300;
  font-style: italic;
}

@media screen and (min-width: 769px) {
  .about-container{
    width: 70%;
  }
  .mision-title{
    font-size: 60px;
   
  }
  .mision-text{
    font-size: 34px;
    width: 80%;
    margin: 30px auto;
  }
  .about-title{
    font-size: 60px;
    margin: 40px 0;
    margin-top: 20px;
  }
}
