.form-modal-container{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  border: rgb(83, 87, 90) solid 3px;
  border-top: none;
  width: 90%;
  max-height: 95%;
  border-radius: 10px;
  margin: auto;
  max-width: 500px;
}

.form-title {
  background-color: rgb(83, 87, 90);
  border-radius: 10px 10px 0 0;
  margin: auto;
  position: relative;
  text-align: center;
  width: 100%;
}
.close-button{
  position: absolute;
  top: 10px;
  right: 5px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.form-title h2 {
  color: white;
  width: 100%;
  margin: auto;
  padding: 10px 5px;
  text-align: center;
  box-sizing: border-box;
  font-size: 20px;
}

form {
  width: 100%;
  margin: auto;
  text-align: center;

}
.form-c {
  background-color: rgb(112, 167, 163); 
  border-radius: 0 0 10px 10px;
}

.form-text {
  color: white;
  margin: 20px auto;
  text-align: left;
  width: 90%;
}

input {
  width: 90%;
  margin-bottom: 14px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
  border: white;
  font-size: 16px;
  outline: none;
}

#first {
  margin-top: 28px;
}

.form-checkbox-container {
 width: 90%;
 margin: 0 auto;
 margin-top: 20px;
}

.form-checkbox-container label {
  display: inline-block;
  text-align: left;
  width: 100%;
  font-size: 16px;
  color: white;
}

.check-container{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}
#cbox2,#cbox1,#cbox3{
  width: 15px;
  height: 15px;
  margin: 0;
}

.form-button-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#form-button {
  background-color: white;
  width: 25%;
  min-width: 120px;
  color: black;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

#form-button:hover {
  background-color: gainsboro;
}

@media screen and (min-width: 769px){
  .form-title h2 {
    font-size: 26px;
    padding: 20px;
  }
  input {
    padding: 12px;
    font-size: 18px;
  }
  .form-checkbox-container label {
    font-size: 18px;
  }
  .form-container, .form-text, input, .form-checkbox-container{
    width: 80%;
  }
  .close-button{
    font-size: 22px;
    right: 8px;
  }
  .form-container{
    max-height: 100%;
  }
}