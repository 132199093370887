.header{
  width: 100%;
  color: white;
  background-color: white;
}
.header-container{
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
}
.header-logo{
  width: 250px;
  margin: 30px 0;
  cursor: pointer;
}
.header-container-text{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 40;
  background-color: #072A39;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transition: 0.5s;
}
.header-open{
  left: 0;
  transition: 0.5s;
}

.open{
  color: #072A39;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
  background-image: url("../../assets/bars-solid.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.closed{
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  font-weight: bold;
}
.header-list{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 50px;
  padding: 0;
  margin: 0;
}
.header-list-item{
  list-style: none;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 180px;
  height: 100%;
  cursor: pointer;
}

.list-item-text{
  margin: 0;
  padding-bottom: 2px;
  width: 100%;
  font-size: 20px;
}

.header-languages-container{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
}
.header-language{
  margin: 0;
  font-size: 18px;
  height: 24px;
  padding: 3px;
  width: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #70A7A3;
  color: white;
  cursor: pointer;
}


@media screen and (min-width: 769px) {
  .header{
    height: 120px;
    color: #606060;
  }
  .header-container{
    flex-direction: row;
    width: 100%;
    height: 70%;
    align-items:flex-end;
    justify-content: center;
  }
  .header-logo{
    width: 300px;
    margin: 0;
  }
  .header-list{
    width: 100%;
    flex-direction: row;
    height: 100%;
    gap: 0;
    justify-content: center;
  }
  .closed, .open{
    display: none;
  }
  .header-list-item{
    width: 135px;
  }
  .header-list-item:hover{
    background-color: #072A39;
    color: white;
  }
  .list-item-text{
    font-size: 14px;
    border-right: 1px solid #072A39;
    border-left: 1px solid #072A39;
  } 
  .first-item{
    border-left: none;
  }

  .last-item{
    border-right: none;
  }
  .header-languages-container{
    height: 100%;
  }
  .header-language{
    height: 100%;
    padding: 2px;
    padding-bottom: 4px;
  }
  .header-container-text{
    flex-direction: row;
    width: 60%;
    max-width: 1000px;
    height: 100%;
    z-index: 30;
    position: static;
    justify-content: space-between;
    background: none;
  }
}

@media screen and (min-width: 1440px) {
  .header{
    height: 140px;
  }
  .header-list-item{
    width: 200px;
  }
  .list-item-text{
    font-size: 20px;
  }
  .header-logo{
    width: 500px;
  }
}