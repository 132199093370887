
.carousel-item-container{
  width: 100vw;
  height: 80%;
  display: flex;
  position: relative;
  gap: 50px;
  transition: 1500ms ease-out all;
  overflow: hidden;
  cursor: pointer;
}

.carousel-item{
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
}

.carousel-image-container{
  width: 90%;
}

.carousel-image{
  width: 100%;
}

.carousel-info-container{
  width: 90%;
}

.carousel-info-title{
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
}

.carousel-title-text{
  color: #E9A45D;
  font-size: 36px;
  font-weight: 700;
  margin: 10px 0;
  margin-top: 30px;
}

.carousel-info-text{
  font-size: 26px;
}

@media screen and (min-width: 769px) {
  .carousel-item-container{
    height: 100%;
    flex-direction: row;
  }

  .carousel-image-container{
    width: 65%;
  } 
  .carousel-info-container{
    width: 35%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
  }

  .carousel-info-title{
    margin-top: 50px;
  }
  .carousel-title-text{
    margin-top: 70px;
    font-size: 48px;
  }

  .carousel-item{
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  
  .carousel-image{
    max-height: 90%;
  }

  .carousel-info-text{
    font-size: 36px;
    text-align: center;
  }
}