.c-cards-container{
  width: 100%;
  margin: 30px 0;
  margin-bottom: 40px;
}

.c-cards-title{
  color: rgb(111, 122, 123);
  font-weight: bold;
  font-size: 26px;
  margin: 10px 0;
  font-style: italic;
}

.divider-container{
  width: 70%;
  height: 2px;
  background-color: #7B858B;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider{
  width: 30%;
  height: 4px;
  background-color: #7B858B;
}

.c-cards{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,300px);
  grid-gap: 30px;
  justify-content: center;
}

@media screen and (min-width:768px) {
  .c-cards-title{
    font-size: 40px;
  }
  .c-cards{
    grid-template-columns: repeat(auto-fit,350px);
  }
}