.about-card-container {
  width: 100%;
  height: 500px;
  max-width: 800px;
  display: flex;
  flex-direction: column-reverse;
}

.about-card-info{
  width: 100%;
  height: 100%;
}

.about-card-title {
  font-weight: 600;
  height: 50px;
  width: 100%;
  background-color: rgb(7, 42, 57);
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-card-title h1 {
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin: 0;
}

.about-image-container{
  width: 100%;
  display: flex;
}
.about-image-container img{
  width: 100%;
}

.about-card-content{
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px);
  background-color: white;
}

.about-card-content p {
  text-align: center;
  font-size: 22px;
  width: 70%;
  color: #303030;
}

@media screen and (min-width: 550px) {
  .about-card-container{
    height: 250px;
    flex-direction: row;
  }
  .right{
    flex-direction: row-reverse;
  }
  .about-image-container{
    width: 45%;
    height: 100%;
  }

  .right-image{
    width: 55%;
  }


  .about-image-container img{
    height: 100%;
  }
  .about-card-info{
    width: 55%;
    height: 100%;
  }

  .right-info{
    width: 45%;
  }
}
