.consultancy-container{
  width: 95%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.consultancy-title{
  color: #E9A45D;
  font-size: 46px;
  margin: 25px 0;
}

.consultancy-logo{
  width: 280px;
  margin-bottom: 30px;
}

.consultancy-image{
  width: 300px;
  margin-bottom: 30px;
}

@media screen and (min-width:769px) {
  .consultancy-title{
    font-size: 60px;
    margin: 40px 0;
    margin-top: 20px;
  }
  .consultancy-logo{
    width: 350px;
  }
  .consultancy-image{
    width: 800px;
    margin-bottom: 10px;
  }
}
